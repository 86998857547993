.TDB-Sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #dedede;
  padding: 56px 32px 20px;
  background-color: var(--surface-primary);
  height: 100vh;
  overflow-y: auto;
  position: absolute;
  width: 278px;
  left: -280px;
  box-sizing: border-box;
  transition-delay: 0.25s;
  transition: 0.5s linear;

  &--expanded {
    left: 0;
  }

  &__logo {
    margin-bottom: 56px;
  }

  &__close {
    display: none;
    position: absolute;
    right: 3rem;
    top: 2rem;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    opacity: 0;
  }

  @media screen and (max-width: 678px) {
    z-index: 1;
    left: auto;
    right: -278px;

    &--expanded {
      left: auto;
      right: 0;
    }

    &__close {
      display: flex;
    }

    &__button {
      opacity: 1;
    }

    &__top {
      display: none;
    }
  }
}
