.TDB-Card {
  display: flex;
  border-radius: 1.5rem;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.14);
  background-color: var(--surface-primary);
  flex-basis: calc(50% - 1rem);
  overflow: hidden;

  &__img {
    width: 50%;
  }

  &__main {
    padding: 20px;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 800px) {
    flex-basis: 100%;
  }
}
