.TDB-IconButton {
  padding: 1rem;
  background-color: var(--surface-primary);
  border-radius: 6px;
  line-height: 1;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}
