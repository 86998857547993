
:root {
    --color-scheme: light;
  
    --background-primary: hsla(200deg, 15%, 98%, 1);
    --background-secondary: hsla(210deg, 20%, 95%, 1);
    --background-deco: hsla(212deg, 32%, 77%, 1);
    --surface-primary: hsla(0deg, 0%, 100%, 1);
    --surface-primary-hovered: hsla(0deg, 0%, 100%, 1);
    --surface-secondary: hsla(200deg, 33%, 98%, 1);
    --surface-secondary-hovered: hsla(200deg, 33%, 98%, 1);
    --surface-secondary-hovered-2: hsla(200deg, 60%, 99%, 1);
    --tooltip: hsla(218deg, 25%, 27%, 1);
    --error-primary: hsla(0deg, 100%, 46%, 1);
    --error-primary-hovered: hsla(2deg, 100%, 41%, 1);
    --error-subdued: hsla(0deg, 100%, 46%, 0.1);
    --warning-subdued: hsla(44deg, 100%, 91%, 0.62);
  
    
    --action-primary: hsla(212deg, 100%, 47%, 1);
    --action-primary-hovered: hsla(212deg, 100%, 37%, 1);
    --action-primary-disabled: hsla(212deg, 40%, 87%, 1);
    --action-surface: hsla(0deg, 0%, 0%, 0);
    --action-surface-hovered: hsla(200deg, 49%, 33%, 0.04);
    --action-surface-selected: hsla(200deg, 49%, 33%, 0.06);
    --action-surface-disabled: hsla(0deg, 0%, 0%, 0.04);
    --action-background: hsla(0deg, 0%, 100%, 0);
    --action-background-hovered: hsla(210deg, 30%, 93%, 1);
    --action-background-selected: hsla(212deg, 100%, 47%, 1);
    --action-background-disabled: hsla(0deg, 0%, 0%, 0.04);
    --action-subdued: hsla(212deg, 100%, 47%, 0.1);
  
    
    --text-main-primary: hsla(0deg, 0%, 0%, 0.87);
    --text-main-secondary: hsla(0deg, 0%, 0%, 0.7);
    --text-main-tertiary: hsla(0deg, 0%, 0%, 0.49);
    --text-main-disabled: hsla(0deg, 0%, 0%, 0.38);
    --text-main-subtle: hsla(0deg, 0%, 0%, 0.08);
    --text-action-primary: hsla(0deg, 0%, 100%, 1);
    --text-action-alternative: hsla(0deg, 0%, 100%, 1);
    --text-action-brand: hsla(212deg, 100%, 47%, 1);
    --text-action-subdued: hsla(224deg, 100%, 15%, 0.76);
    --text-deco-primary: hsla(224deg, 47%, 38%, 1);
    --text-deco-secondary: hsla(222deg, 34%, 55%, 1);
    --text-error: hsla(0deg, 100%, 46%, 1);
    --text-error-hovered: hsla(2deg, 100%, 41%, 1);
    --text-warning: hsla(54deg, 42%, 68%, 1);
  
    
    --border-input: hsla(212deg, 18%, 84%, 1);
    --border-input-hovered: hsla(212deg, 18%, 74%, 1);
    --border-input-selected: hsla(212deg, 100%, 47%, 1);
    --border-input-disabled: hsla(0deg, 0%, 90%, 1);
    --border-input-error: hsla(0deg, 100%, 46%, 1);
    --border-input-error-hovered: hsla(2deg, 100%, 41%, 1);
    --border-input-warning: hsla(54deg, 42%, 68%, 1);
    --border-tooltip: rgba(255, 255, 255, 0.1);
  
    
    --shadow-small: 0 1px 3px rgba(0, 0, 0, 0.16);
    --shadow-medium: 0 2px 10px rgba(0, 0, 0, 0.16);
    --shadow-large: 0 6px 18px rgba(0, 0, 0, 0.16);
    --shadow-xlarge: 0 16px 38px rgba(0, 0, 0, 0.16);
    --shadow-medium-brand: 0 6px 10px rgba(0, 112, 240, 0.11);
    --shadow-small-elevated: 0 1px 3px rgba(0, 0, 0, 0.16);
    --shadow-small-elevated-thick: 0 1px 3px rgba(0, 0, 0, 0.16);
    --shadow-medium-elevated: 0 2px 10px rgba(0, 0, 0, 0.16);
    --shadow-large-elevated: 0 6px 18px rgba(0, 0, 0, 0.16);
    --shadow-large-elevated-thick: 0 6px 18px rgba(0, 0, 0, 0.16);
    --shadow-xlarge-elevated: 0 16px 38px rgba(0, 0, 0, 0.16);
  
    
    --skeleton: hsla(0deg, 0%, 98%, 1);
    --skeleton-lighten: hsla(0deg, 0%, 99%, 1);
    --solid-neutral: hsla(180deg, 2%, 76%, 1);
  
    
    --white-transparent: hsla(0deg, 0%, 100%, 0.1);
    --black-transparent: hsla(0deg, 0%, 0%, 0.1);
    --white: hsla(0deg, 0%, 100%, 1);
    --favorite-primary: hsla(50deg, 100%, 47%, 1);
  
    
    --array-primary: linear-gradient(180deg, #0070f0 0%, #0065d8 100%);
    --array-hovered: linear-gradient(90deg, rgba(1, 112, 239, 0.2) 0%, rgba(1, 112, 239, 0) 88.57%);
    --array-secondary: hsla(212deg, 100%, 90%, 1);
    --notebook-primary: linear-gradient(180deg, #ffd53f 0%, #cd9400 100%);
    --notebook-hovered: linear-gradient(90deg, rgba(255, 218, 87, 0.3) 0%, rgba(255, 218, 87, 0) 88.57%);
    --notebook-secondary: hsla(36deg, 95%, 32%, 1);
    --mlmodel-primary: linear-gradient(180deg, #ff398c 0%, #ce0056 100%);
    --mlmodel-hovered: linear-gradient(90deg, rgba(255, 56, 139, 0.2) 0%, rgba(255, 56, 139, 0) 88.57%);
    --mlmodel-secondary: hsla(335deg, 100%, 90%, 1);
    --udf-primary: linear-gradient(180deg, #22d74a 0%, #00b628 100%);
    --udf-hovered: linear-gradient(90deg, rgba(34, 216, 74, 0.2) 0%, rgba(24, 205, 64, 0) 88.57%);
    --udf-secondary: hsla(133deg, 73%, 90%, 1);
    --dashboard-primary: linear-gradient(180deg, #0070f0 0%, #0065d8 100%);
    --dashboard-hovered: linear-gradient(90deg, rgba(1, 112, 239, 0.2) 0%, rgba(1, 112, 239, 0) 88.57%);
    --dashboard-secondary: hsla(212deg, 100%, 90%, 1);

    /** Viewports **/
    --tablet-viewport: 800px;
  }