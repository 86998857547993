.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-x: hidden;

  &__main {
    flex: 1;
    display: flex;
    transition: 0.5s ease-in-out;

    &--menuExpanded {
      margin-left: 278px;
      transition-delay: 0.25s;

      @media screen and (max-width: 678px) {
        margin-left: 0;
      }
    }
  }
}
