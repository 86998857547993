.TDB-Home {
  margin: 0 auto;
  padding: 57px 4rem;
  max-width: 1280px;
  flex-direction: column;

  &__title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  &__grid {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
  }

  &__header {
    display: none;
  }

  @media screen and (max-width: 678px) {
    &__button {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      justify-content: center;
    }
  }
}
