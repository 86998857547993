@import "./assets/css/tokens.css";

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 8px;
  line-height: 3rem;
  font-weight: 400;

  color-scheme: light dark;
  color: var(--text-main-secondary);
  background-color: #fff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;

  &:hover {
    color: #535bf2;
  }
}

.btn {
  background-color: var(--action-primary);
  border-radius: 1rem;
  color: var(--text-action-primary) !important;
  font-size: 14px;
  font-weight: 600;
  padding: 1.25rem 2rem;
  line-height: 20px;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 32px;
  line-height: 38px;
}

button {
  border-radius: 1rem;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;

  &:hover {
    border-color: #646cff;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.flex {
  display: flex;
}

.flex-between {
  justify-content: space-between;
}

.flex-center {
  align-items: center;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-half {
  margin-right: 0.5rem;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
